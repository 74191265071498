import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {

  connect() {
    // console.log("Bound to: ", this.element);
    $(this.element).progress();
  }
  
}
